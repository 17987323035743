import AxiosClient from "./axiosClient";

const API_ENDPOINT = "notification/";

export const getAllNotification = () => {
  return AxiosClient.get(API_ENDPOINT);
};

export const updateStatusNotification = (id, status) => {
  return AxiosClient.put(API_ENDPOINT + `${id}?status=${status}`);
};
