import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

const reloadSlice = createSlice({
   name: "reload",
   initialState,
   reducers: {
      setReload: (state, action) => {
         state = Math.random();
      },
   },
});

export const { setReload } = reloadSlice.actions;
export default reloadSlice.reducer;
