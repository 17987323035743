import React, { useId } from 'react'
import './CustomRow.scss'
import { TbPencil } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { Input } from '../../../../../components/Input/Input';

function CustomRow({ comp, compSelected, handleRowCompClick, editButton, index, setOpenEditComp, openEditComp, register, handleDeleteBrand, activeClassName, setActiveClassName, setFocus }) {
    return (
        <tr
            onClick={() => {
                handleRowCompClick(comp)
                if (comp?._id === compSelected) {
                    setOpenEditComp(true)
                }
                else {
                    setOpenEditComp(false)
                }
            }}
        >
            <td
                style={comp?._id === compSelected ? { color: '#3B54A5', fontWeight: 'bold', textDecoration: 'underline' } : {}}
            >
                {`${index < 10 ? '0' : ''}${index}`}
            </td>
            {openEditComp && comp?._id === compSelected ? (
                <td style={comp?._id === compSelected ? { backgroundColor: '#e4ffdd', borderRadius: '0px' } : {}}>
                    <Input
                        setFocus={setFocus}
                        className='color-input--edit'
                        name={'compNameEdit'}
                        defaultValue={comp?.name}
                        register={register}
                        placeholder={'Tên hãng sơn'}
                    />
                </td>
            ) : (
                <td>
                    <div className='comp-name-contain'>
                        <div className='comp-name' style={comp?._id === compSelected ? { color: '#3B54A5', fontWeight: 'bold', textDecoration: 'underline' } : {}}>
                            {comp?.name}
                        </div>
                        {!editButton && (
                            <div className='group-icons'>
                                <div>
                                    <TbPencil
                                        style={{ cursor: 'pointer', zIndex: 1 }}
                                        onMouseDown={() => {
                                            setOpenEditComp(true)
                                            setActiveClassName('active-edit-selected')
                                        }}
                                        onMouseUp={() => {
                                            setActiveClassName(null)
                                        }}
                                        color='#696969'
                                        size={20}
                                    />
                                </div>
                                <div>
                                    <MdCancel
                                        style={{ cursor: 'pointer', zIndex: 1 }}
                                        onMouseDown={() => handleDeleteBrand(comp?._id)}
                                        color='#FF0000'
                                        size={20}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </td>
            )}
        </tr>
    );
}

export default CustomRow