import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import UserMan from "../pages/Tab/UserMan/UserMan";
import ConsMan from "../pages/Tab/ConsMan/ConsMan";
import CompMan from "../pages/Tab/CompMan/CompMan";
import Solution from "../pages/Tab/ConsMan/components/Solution/Solution";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="/" element={<UserMan />} />
        <Route path="/constructor" element={<ConsMan />} />
        <Route path="/company" element={<CompMan />} />
        <Route path="/solution" element={<Solution />} />
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
