export const NotiStatus = {
  PENDING: "PENDING",
  AGREE: "AGREE",
  DENY: "DENY",
};

export const NotiType = {
  REGISTER: "REGISTER",
  PASSWORD: "PASSWORD",
};
