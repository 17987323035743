import AxiosClient from "./axiosClient";

const API_ENDPOINT = "user/";

export const registerUser = (data) => {
  return AxiosClient.post(API_ENDPOINT + "register", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getUsers = (data) => {
  return AxiosClient.get(API_ENDPOINT, { params: data });
};

export const getUsersForExcel = () => {
  return AxiosClient.get(API_ENDPOINT + "excel");
};

export const editUser = (data) => {
  return AxiosClient.put(API_ENDPOINT + `${data.id}`, data.data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteUser = (id) => {
  return AxiosClient.delete(API_ENDPOINT + `${id}`);
};
