import AxiosClient from "./axiosClient";

const API_ENDPOINT = "brand-paint/";

export const getBrandPaints = (data) => {
  return AxiosClient.get(API_ENDPOINT, { params: data });
};

export const createBrandPaint = (data) => {
  return AxiosClient.post(API_ENDPOINT, data);
};

export const editBrandPaint = (data) => {
  return AxiosClient.put(API_ENDPOINT + `${data.brandId}`, data.data);
};

export const deleteBrandPaint = (id) => {
  return AxiosClient.delete(API_ENDPOINT + `${id}`);
};

export const createBrandPaintByExcel = (data) => {
  return AxiosClient.post(API_ENDPOINT + "excel", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const searchBrandPaintApi = (params, id) => {
  return AxiosClient.get(`paint/${id}/brand-paint`, { params: params });
};
