import "./Login.scss";
import { useState, Fragment } from "react";
import { ButtonAdd } from "../../components/Button/Button";
import { set, useForm } from "react-hook-form";
import { login } from "../../services/authServices";
import { removeToken, setToken } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { getUserInfor } from "../../store/auth/authThunk";
import Notiflix from "notiflix";

function Login() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const mutation = useMutation({
    mutationFn: login,
    onSuccess: async (data) => {
      Notiflix.Notify.success("Đăng nhập thành công !!!", {
        timeout: 1000,
        fontFamily: "Montserrat",
        fontSize: "16px",
      });
      await setToken(data.data.response.access_token);
      dispatch(getUserInfor());
      setTimeout(() => removeToken(), 36 * 100 * 1000);
    },
    onError: (error) => {
      Notiflix.Notify.failure(
        "Đăng nhập thất bại!!!\t Không có quyền truy cập",
        {
          timeout: 1000,
          fontFamily: "Montserrat",
          fontSize: "16px",
        }
      );
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="container-login">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="header">Đăng Nhập</div>
        <div className="input-group">
          <div className="input-field">
            <span>Số điện thoại</span>
            <input
              type="text"
              placeholder="Nhập số điện thoại"
              {...register("phone", { required: true, pattern: /^0[0-9]{9}$/ })}
            />
            {errors.phone?.type === "required" && (
              <p className="error-message">
                Số điện thoại không được để trống !!!
              </p>
            )}
            {errors.phone?.type === "pattern" && (
              <p className="error-message">Số điện thoại sai định dạng !!!</p>
            )}
          </div>
          <div className="input-field">
            <span>Mật khẩu</span>
            <input
              type="password"
              placeholder="Nhập mật khẩu"
              {...register("password", { required: true, minLength: 6 })}
            />
            {errors.password?.type === "required" && (
              <p className="error-message">Mật khẩu không được để trống !!!</p>
            )}
            {errors.password?.type === "minLength" && (
              <p className="error-message">Mật khẩu quá ngắn !!!</p>
            )}
          </div>
        </div>
        <div className="button-login">
          <ButtonAdd>Đăng nhập</ButtonAdd>
        </div>
      </form>
    </div>
  );
}

export default Login;
