import { setLogoSubmit } from "../../../../store/user/userSlice";
import Notiflix from "notiflix";
import { unwrapResult } from "@reduxjs/toolkit";
import { getLogoUser } from "../../../../store/cons/consThunk";

export default {
   handleScroll: (
      tableRef,
      hasNextPage,
      isFetchingNextPage,
      lastScrollTop,
      setIsLoadingMore,
      fetchNextPage,
      setLastScrollTop
   ) => {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      if (isBottom && hasNextPage && !isFetchingNextPage && scrollTop > lastScrollTop) {
         setIsLoadingMore(true);
         setTimeout(() => {
            fetchNextPage().then(() => {
               setIsLoadingMore(false);
            });
         }, 500);
      }
      setLastScrollTop(scrollTop);
   },
   handleRowClick: async (
      dispatch,
      setLogoUser,
   ) => {
      try {
         // console.log("row click");
         //  const logoPromises = user.logo.map((logo) =>
         //     dispatch(getLogoUser({ imageName: logo }))
         //  );

         //  const logoResults = await Promise.all(logoPromises);
         //  const images = logoResults.map((result) => unwrapResult(result));
         //  setLogoUser(logoResults);
      } catch (error) {
         console.error("Error fetching logos:", error);
      }
   },
   showModalAddUser: (setIsModalOpen) => { },
   handleAddUser: (data, logo, mutation, methods) => {
      const newObj = { ...data, logo: logo };
      Notiflix.Loading.circle("Đang xử lý...");
      mutation.mutate(newObj);
      methods.reset();
   },
   handleEditUser: async (
      data,
      logo,
      logoUsers,
      userSelected,
      edit,
      dispatch,
      methods
   ) => {
      const oldBrandPaint = userSelected.brandPaint.map((item) => item._id);
      const newObj = {
         ...data,
         brandPaint: data?.brandPaint ?? oldBrandPaint,
         logo: logo,
         nameLogo: "logo name",
         editLogos: logoUsers,
      };
      // console.log(newObj);
      Notiflix.Loading.circle("Đang xử lý...");
      edit.mutate({ id: userSelected._id, data: newObj })
   },
   handleDeleteUser: (userId, delUser) => {
      delUser.mutate(userId);
   },
   handleCancel: (
      dispatch,
      methods,
      setIsModalOpen,
   ) => {
      dispatch(setLogoSubmit(null));
      methods.reset();
      setIsModalOpen(false);
   },
   handleSearch: (data) => { },
};
