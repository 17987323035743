import "./CompMan.scss";
import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
  createRef,
  Fragment,
} from "react";
import {
  ButtonAdd,
  ButtonLogout,
  Button,
  ButtonFile,
} from "../../../components/Button/Button";
import { Input } from "../../../components/Input/Input";
import { set, useForm, Controller, FormProvider } from "react-hook-form";
import { TbPencil } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { CustomRow } from "./components/index";
import { useInfiniteQuery, useQuery, useMutation } from "@tanstack/react-query";
import {
  getBrandPaints,
  createBrandPaint,
  editBrandPaint,
  deleteBrandPaint,
  createBrandPaintByExcel,
} from "../../../services/brandPaintServices";
import { getListPaints } from "../../../services/paintServices";
import Notiflix from "notiflix";
import handlers from "./CompManHandler/CompManHandler";
import { Flex, Grid } from "antd";

function CompMan() {
  let searchParams = "";
  let searchColorParams = "";
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    control,
    formState: { errors },
  } = useForm();
  const [activeClassName, setActiveClassName] = useState("");
  const [addComp, setAddComp] = useState(false);
  const [colorSelected, setColorSelected] = useState(null);
  const [rowColorSelect, setRowColorSelect] = useState(null);
  const [editColor, setEditColor] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [compColors, setCompColors] = useState([]);
  const [compSelected, setCompSelected] = useState(null);
  const [compName, setCompName] = useState("");
  const [firstClick, setFirstClick] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [openEditComp, setOpenEditComp] = useState(false);
  const [lastScrollTop1, setLastScrollTop1] = useState(0);
  const [lastScrollTop2, setLastScrollTop2] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const tableRef1 = useRef();
  const tableRef2 = useRef();
  const inputRefs = useRef([createRef(), createRef(), createRef()]);

  const {
    data: listBrands,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["listBrandPaints"],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getBrandPaints({
        page: pageParam,
        pageSize: searchParams ? 1 : 15,
        search: searchParams ?? null,
      });
      setTotalItem(response.data.meta.itemCount);
      return response.data.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (lastPage.length == 0) {
        return lastPageParam;
      } else {
        return lastPageParam + 1;
      }
    },
  });

  const {
    data: listColors,
    fetchNextPage: fetchNextPageColors,
    hasNextPage: hasNextPageColors,
    isFetchingNextPage: isFetchingNextPageColors,
    refetch: refetchColors,
  } = useInfiniteQuery({
    queryKey: ["listColors", compSelected],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getListPaints(compSelected, {
        page: pageParam,
        pageSize: searchColorParams ? 1 : 15,
        search: searchColorParams ?? null,
      });
      return response.data.data;
    },
    enabled: !!compSelected,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (lastPage.length == 0) {
        return lastPageParam;
      } else {
        return lastPageParam + 1;
      }
    },
  });

  const handleScroll1 = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableRef1.current;
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (
      isBottom &&
      hasNextPage &&
      !isFetchingNextPage &&
      scrollTop > lastScrollTop1
    ) {
      setTimeout(() => {
        fetchNextPage();
      }, 500);
    }
    setLastScrollTop1(scrollTop);
  };

  const handleScroll2 = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableRef2.current;
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (
      isBottom &&
      hasNextPageColors &&
      !isFetchingNextPageColors &&
      scrollTop > lastScrollTop2
    ) {
      setTimeout(() => {
        fetchNextPageColors();
      }, 500);
    }
    setLastScrollTop2(scrollTop);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].current.focus();
      }
    } else if (event.key === "Backspace") {
      if (event.target.value === "") {
        if (index > 0) {
          inputRefs.current[index - 1].current.focus();
        }
      }
    }
  };

  const createBrand = useMutation({
    mutationFn: createBrandPaint,
    onSuccess: async (data) => {
      setAddComp(false);
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Tạo thành công !!!");
      }, 500);
      refetch();
    },
    onError: (error) => {
      setAddComp(false);
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error?.response?.data?.message);
      }, 1000);
    },
  });

  const editBrand = useMutation({
    mutationFn: editBrandPaint,
    onSuccess: async (data) => {
      setColorSelected(null);
      setRowColorSelect(null);
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Chỉnh sửa thành công !!!");
      }, 500);
      refetchColors();
    },
    onError: (error) => {
      setEditColor(false);
      setEditButton(true);
      setOpenEditComp(false);
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error?.response?.data?.message);
      }, 1000);
    },
  });

  const deleteBrand = useMutation({
    mutationFn: deleteBrandPaint,
    onSuccess: async (data) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Xóa thành công !!!");
      }, 500);
      setCompSelected(null);
      setEditColor(false);
      refetch();
      refetchColors();
    },
    onError: (error) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error?.response?.data?.message);
      }, 1000);
    },
  });

  const deleteColor = useMutation({
    mutationFn: editBrandPaint,
    onSuccess: async (data) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Chỉnh sửa thành công !!!");
      }, 500);
      refetch();
      refetchColors();
    },
    onError: (error) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error?.response?.data?.message);
      }, 1000);
    },
  });

  const handleRowCompClick = (comp) => {
    // Kiểm tra nếu đây là lần nhấn chuột thứ hai
    if (firstClick && comp?._id == compSelected) {
      if (!editColor) {
        setCompSelected(null);
        setAddComp(false);
        setEditButton(true);
        setEditColor(false);
        // Đặt lại trạng thái của lần nhấn chuột đầu tiên
        setFirstClick(false);
      }
    } else {
      if (!editColor) {
        setCompColors(comp?.colors);
        setCompSelected(comp?._id);
        setCompName(comp?.name);
        setAddComp(false);
        setOpenEdit(false);
        setEditButton(true);
        setEditColor(false);
        // Cập nhật trạng thái của lần nhấn chuột đầu tiên
        setFirstClick(true);
      }
    }
  };
  useEffect(() => {
    setValue("colorHexEdit", colorSelected?.hex);
    setValue("colorRgbEdit", colorSelected?.rgb);
    setValue("compNameEdit", compName);
  }, [colorSelected, setValue, compSelected]);
  const handleRowColorClick = (color) => {
    setRowColorSelect(color?._id);
    setColorSelected(color);
  };
  const openAddComp = () => {
    setCompSelected(null);
    setAddComp(true);
  };
  const cancelAddComp = () => {
    setAddComp(false);
  };
  const saveAddComp = (compName) => {
    Notiflix.Loading.circle("Đang tạo hãng sơn...");
    createBrand.mutate({ name: compName });
  };
  const saveEditComp = (compName) => {
    Notiflix.Loading.circle("Đang cập nhật hãng sơn...");
    editBrand.mutate({ brandId: compSelected, data: { name: compName } });
  };
  const handleDeleteBrand = (id) => {
    deleteBrand.mutate(id);
  };

  const openEditColor = () => {
    setEditColor(true);
    setEditButton(false);
  };
  const saveAddColor = (colorHex, colorRgb) => {
    Notiflix.Loading.circle("Vui lòng đợi trong giây lát...");
    editBrand.mutate({
      brandId: compSelected,
      data: { name: compName, paints: [{ hex: colorHex, rgb: colorRgb }] },
    });
  };
  const saveEditColor = (colorHexEdit, colorRgbEdit) => {
    Notiflix.Loading.circle("Vui lòng đợi trong giây lát...");
    editBrand.mutate({
      brandId: compSelected,
      data: {
        paints: [{ _id: rowColorSelect, hex: colorHexEdit, rgb: colorRgbEdit }],
      },
    });
  };
  const handleDeleteColor = (colorId) => {
    setTimeout(() => {
      setActiveClassName("active-delete-selected");
    }, 100);
    deleteColor.mutate({
      brandId: compSelected,
      data: { paints: [{ _id: colorId }] },
    });
  };
  const handleCancel = () => {
    setIsModalEditOpen(false);
    setEditButton(true);
    setEditColor(false);
    setOpenEdit(false);
    setActiveClassName(null);
    setOpenEditComp(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    const colorRgb = `rgb(${inputRefs.current[0].current?.value},${inputRefs.current[1].current?.value},${inputRefs.current[2].current?.value})`;
    if (data?.compName) {
      saveAddComp(data?.compName);
    } else if (data?.compNameEdit != compName && data?.compNameEdit) {
      saveEditComp(data?.compNameEdit);
    } else if (data?.colorHex && colorRgb) {
      if (
        !inputRefs.current[0].current?.value ||
        !inputRefs.current[1].current?.value ||
        !inputRefs.current[2].current?.value
      ) {
        return;
      }
      saveAddColor(data?.colorHex, colorRgb);
    } else if (data?.colorHexEdit && data?.colorRgbEdit) {
      saveEditColor(data?.colorHexEdit, data?.colorRgbEdit);
    } else if (data?.search) {
      const arr = listBrands?.pages.flatMap((pageData, i) => {
        return pageData.map((comp) => comp?.name);
      });
      if (arr.includes(data?.search)) {
        console.log("Tìm hãng sơn");
        searchParams = data?.search;
        setCompSelected(null);
        refetch();
      } else {
        if (!data?.search) {
          return;
        }
        console.log("Tìm màu sơn");
        searchColorParams = data?.search;
        refetchColors();
      }
    } else {
      searchParams = "";
      searchColorParams = "";
      refetch();
      refetchColors();
    }
    setColorSelected(null);
    setEditButton(true);
    reset();
  };
  const getFileExcel = useMutation({
    mutationFn: createBrandPaintByExcel,
    onSuccess: async (data) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Thêm dữ liệu thành công !!!");
      }, 500);
      refetch();
      refetchColors();
    },
    onError: (error) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error?.response?.data?.message);
      }, 1000);
    },
  });
  const handleGetFile = async (e) => {
    const formData = new FormData();
    formData.append("excel", e.target.files[0]);
    Notiflix.Loading.circle();
    getFileExcel.mutate(formData);
  };

  return (
    <form className="body-content-comp" onSubmit={handleSubmit(onSubmit)}>
      <div className="header">
        <div className="title">
          <span>HỆ THỐNG MÀU SẮC</span>
        </div>
        <Flex justify="center" align="center" gap={20}>
          <div className="search-container">
            <div className="input-search-container">
              <Input
                className="input-cons"
                register={register}
                placeholder="Nhập tìm kiếm..."
                name="search"
              />
            </div>
            <div className="btn-search-container">
              <ButtonAdd className="btn-add-cons">Tìm kiếm</ButtonAdd>
            </div>
          </div>
          <ButtonFile onChange={handleGetFile} />
        </Flex>
      </div>
      <div className="table-container">
        <div className="table-1">
          <div className="table-header-contain-1">
            <table className="table-header-1">
              <tbody>
                <tr>
                  <td>STT</td>
                  <td style={{ borderLeft: "1px solid white" }}>Hãng sơn</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-body" onScroll={handleScroll1} ref={tableRef1}>
            <table className="table-content-1">
              <tbody>
                {listBrands?.pages.flatMap((pageData, i, asd) =>
                  pageData.map((comp, index) => {
                    return (
                      <Fragment>
                        <CustomRow
                          key={comp?._id}
                          index={index + 1 + i * 15}
                          comp={comp}
                          compSelected={compSelected}
                          handleRowCompClick={handleRowCompClick}
                          editButton={editButton}
                          openEditComp={openEditComp}
                          setOpenEditComp={setOpenEditComp}
                          register={register}
                          setFocus={setFocus}
                          setActiveClassName={setActiveClassName}
                          handleDeleteBrand={handleDeleteBrand}
                        />
                      </Fragment>
                    );
                  })
                )}
                {addComp && (
                  <tr>
                    <td>
                      {listBrands?.pages.flatMap((pageData, i) =>
                        pageData.length < 9
                          ? () => `0${pageData.length * (i + 1) + 2}`
                          : totalItem + 1
                      )}
                    </td>
                    <td>
                      <div className="comp-name">
                        <Input
                          placeholder={"Thêm hãng sơn"}
                          className="comp-name-input"
                          name={"compName"}
                          register={register}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-2">
          <div className="table-header-contain-2">
            <table className="table-header-2">
              <tbody>
                <tr>
                  <td>Mã màu</td>
                  <td style={{ borderLeft: "1px solid white" }}>Mã màu RGB</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-body" onScroll={handleScroll2} ref={tableRef2}>
            <table className="table-content-2">
              <tbody>
                {editColor && (
                  <tr>
                    <td>
                      <Input
                        className="color-input"
                        name={"colorHex"}
                        register={register}
                        placeholder={"Thêm mã màu sơn"}
                      />
                    </td>
                    <td>
                      <span style={{ fontSize: "14px", fontWeight: 400 }}>
                        rgb(
                        {inputRefs.current.map((ref, index) => (
                          <Fragment>
                            <input
                              style={{
                                width: "30px",
                                border: "none",
                                outline: "none",
                                fontSize: "14px",
                              }}
                              maxLength={3}
                              key={index}
                              ref={ref}
                              onKeyDown={(event) => handleKeyDown(event, index)}
                            />
                            {index < 2 ? "," : ""}
                          </Fragment>
                        ))}
                        )
                      </span>
                    </td>
                  </tr>
                )}
                {listColors?.pages.flatMap((pageData, i) =>
                  pageData.map((color, index) =>
                    !editButton && compSelected ? (
                      <tr
                        key={color?.id}
                        className={
                          rowColorSelect === color?._id ? activeClassName : ""
                        }
                        onClick={() => {
                          if (openEdit && rowColorSelect === color?._id) {
                          } else {
                            setOpenEdit(false);
                            handleRowColorClick(color);
                            setActiveClassName("active-row-selected");
                          }
                        }}
                      >
                        <td>
                          {openEdit && rowColorSelect == color?._id ? (
                            <Input
                              type={"text"}
                              className="color-input--edit"
                              name={"colorHexEdit"}
                              defaultValue={color?.hex}
                              register={register}
                            />
                          ) : (
                            <div className="color-code-contain">
                              <div className="color-code">{color?.hex}</div>
                              {!editButton && (
                                <div className="group-icons-1">
                                  <div style={{ cursor: "pointer", zIndex: 1 }}>
                                    <TbPencil
                                      style={{ cursor: "pointer", zIndex: 1 }}
                                      onMouseDown={() => {
                                        handleRowColorClick(color);
                                        setOpenEdit(true);
                                        setActiveClassName(
                                          "active-edit-selected"
                                        );
                                      }}
                                      onMouseUp={() => {
                                        setActiveClassName(null);
                                      }}
                                      color="#696969"
                                      size={20}
                                    />
                                  </div>
                                  <div>
                                    <MdCancel
                                      style={{ cursor: "pointer", zIndex: 1 }}
                                      onMouseDown={() =>
                                        handleDeleteColor(color?._id)
                                      }
                                      color="#FF0000"
                                      size={20}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </td>
                        <td>
                          {openEdit && rowColorSelect == color?._id ? (
                            <Input
                              className="color-input--edit"
                              name={"colorRgbEdit"}
                              defaultValue={color?.rgb}
                              register={register}
                            />
                          ) : (
                            <div className="color-name-contain">
                              <div className="color-name">{color?.rgb}</div>
                              {!editButton && (
                                <div className="group-icons-2">
                                  <div>
                                    <TbPencil
                                      style={{ cursor: "pointer", zIndex: 1 }}
                                      onMouseDown={() => {
                                        handleRowColorClick(color);
                                        setOpenEdit(true);
                                        setActiveClassName(
                                          "active-edit-selected"
                                        );
                                      }}
                                      onMouseUp={() => {
                                        setActiveClassName(null);
                                      }}
                                      color="#696969"
                                      size={20}
                                    />
                                  </div>
                                  <div>
                                    <MdCancel
                                      onMouseDown={() =>
                                        handleDeleteColor(color?._id)
                                      }
                                      style={{ cursor: "pointer", zIndex: 1 }}
                                      color="red"
                                      size={20}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr key={color.id}>
                        <td style={{ fontSize: "14px", fontWeight: 400 }}>
                          {color?.hex}
                        </td>
                        <td style={{ fontSize: "14px", fontWeight: 400 }}>
                          {color?.rgb}
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="btn-container">
        <div className="btn-add-comp">
          <ButtonAdd onClick={openAddComp}>Thêm hãng sơn</ButtonAdd>
        </div>
        {editButton && compSelected && (
          <div className="btn-edit-comp-contain">
            <ButtonLogout className="btn-edit-comp" onClick={openEditColor}>
              Chỉnh sửa
            </ButtonLogout>
          </div>
        )}
        {addComp && (
          <div className="btn-add-comp-1">
            <div className="btn-cancel-comp-1">
              <ButtonAdd onClick={cancelAddComp}>Huỷ</ButtonAdd>
            </div>
            <div className="btn-save-comp-1">
              <ButtonAdd onSubmit={onSubmit}>Lưu</ButtonAdd>
            </div>
          </div>
        )}
        {!editButton && !addComp && (
          <div className="btn-add-comp-1">
            <div className="btn-cancel-comp-1">
              <ButtonAdd onClick={handleCancel}>Huỷ</ButtonAdd>
            </div>
            <div className="btn-save-comp-1">
              <ButtonAdd onSubmit={onSubmit}>Lưu</ButtonAdd>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export default CompMan;
