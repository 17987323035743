import axios from "axios";
import { stringify, parse } from "qs";
import { getToken } from "../utils/localStorage";

export const API_ENDPOINT = "https://api.aicolor.vn/";
// export const API_ENDPOINT = "http://192.168.1.91:3007/";
export const API_ENDPOINT_AI = "https://ai.aicolor.vn/id_images";

const AxiosClient = axios.create({
  baseURL: API_ENDPOINT,
  responseType: "json",
  timeout: 50000,
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
});

AxiosClient.interceptors.request.use(
  async (config) => {
    const newConfig = config;
    const token = getToken();
    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default AxiosClient;
