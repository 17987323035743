import { useState, Fragment, useEffect } from "react";
import { getImageCons } from "../../../../../services/imageServices";
import { Card, Image } from "antd";
import "./CustomCard.scss";
import Compressor from "compressorjs";
import MenuSelectCons from "../MenuSelectCons/MenuSelectCons";

function CustomCard({ cons, index, refetch }) {
  const [image, setImage] = useState("");

  useEffect(() => {
    const handleImage = async () => {
      await getImageCons({ imageName: cons.actualPhoto })
        .then(async (imageData) => {
          const response = await fetch(imageData.request.responseURL);
          const blob = await response.blob();
          const file = new File([blob], "image.jpg", { type: blob.type });
          new Compressor(file, {
            quality: 0.4,
            success(result) {
              setImage(URL.createObjectURL(result));
            },
            error(err) {
              console.log(err.message);
            },
          });
        })
        .catch((error) => { });
    };
    handleImage();
  }, [cons]);

  return (
    <Fragment>
      <Card className="card">
        <MenuSelectCons cons={cons} refetch={refetch} />
        <div
          className="card-text-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flex: 1,
            }}
            className="card-text"
          >
            {cons?.code ? `${cons?.code}` : `#${index + 1} ( mã công trình)`}
          </span>
        </div>
        <div className="card-image-container">
          <Image
            loading="lazy"
            className="card-image"
            src={image}
            fallback="https://via.placeholder.com/300x200"
            preview={false}
          />
        </div>
      </Card>
    </Fragment>
  );
}

export default CustomCard;
