import "./ConsMan.scss";
import React, { useEffect, useState, useRef } from "react";
import { ButtonAdd } from "../../../components/Button/Button";
import {
  ModalAddCons,
  ModalEditCons,
  ModalViewCons,
} from "../../../components/CustomModal/CustomModal";
import { useForm, FormProvider } from "react-hook-form";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  getCons,
  editConstruct,
  createConstruct,
  editDetailConstruct,
} from "../../../services/constructServices";
import { getUsers } from "../../../services/userServices";
import CustomCard from "./components/CustomCard/CustomCard";
import { useSelector, useDispatch } from "react-redux";
import { getImgCons } from "../../../store/cons/consThunk";
import { unwrapResult } from "@reduxjs/toolkit";
import { resetImageCons } from "../../../store/cons/consSlice";
import { Input } from "../../../components/Input/Input";
import Notiflix from "notiflix";
import { setModalCons } from "../../../store/modal/modalSlice";

function ConsMan() {
  let searchParams = "";
  const tableRef = useRef();
  const methods = useForm();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const render = useSelector((state) => state.cons.render);
  const { imageActual, image3d, imageId, filterCons } = useSelector(
    (state) => state.cons
  );
  const { consDetail, isModalEditConsOpen } = useSelector(
    (state) => state.modal.modalCons
  );

  const [consSelect, setConsSelect] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [thumbnail, setThumbnail] = useState();
  const [isModalViewConsOpen, setIsModalViewConsOpen] = useState(false);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ["listCons", filterCons],
      queryFn: async ({ pageParam = 1 }) => {
        const response = await getCons({
          page: pageParam,
          pageSize: searchParams ? 0 : 12,
          search: searchParams,
          user: filterCons.user,
          categoryConstruction: filterCons.categoryConstruction,
          consDefault: filterCons.consDefault,
        });
        console.log("🆘🆘🆘🆘", response.data.data);
        return response.data.data;
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage, pages, lastPageParam) => {
        if (lastPage.length == 0) {
          return lastPageParam;
        } else {
          return lastPageParam + 1;
        }
      },
    });
  const { data: listUsersCons } = useQuery({
    queryKey: ["listUsersCons"],
    queryFn: async () => {
      const response = await getUsers();
      return response.data.data;
    },
    enabled: !!consSelect,
  });

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (
      isBottom &&
      hasNextPage &&
      !isFetchingNextPage &&
      scrollTop > lastScrollTop
    ) {
      setIsLoadingMore(true);
      setTimeout(() => {
        fetchNextPage().then(() => {
          setIsLoadingMore(false);
        });
      }, 500);
    }
    setLastScrollTop(scrollTop);
  };

  useEffect(() => {}, [thumbnail, render]);

  const handleAddCons = (data) => {
    Notiflix.Loading.circle("Đang tạo công trình...");
    const newObj = {
      categoryConstruction: data.selectCateCons,
      code: data.idCons,
      actualPhoto: imageActual,
      photo3d: image3d,
      photoId: imageId,
    };
    createConstruct(newObj)
      .then((res) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Tạo công trình thành công");
        refetch();
      })
      .catch((err) => {
        console.log(err);
        Notiflix.Notify.failure("Tạo công trình thất bại");
        Notiflix.Loading.remove();
      });
    methods.reset();
    setIsModalOpen(false);
    dispatch(resetImageCons());
  };

  const handleEditCons = (data) => {
    // console.log({ ...data, imageActual, image3d, imageId });

    const newObj = {
      idCons: consDetail._id,
      data: {
        categoryConstruction:
          data?.selectCateCons ?? consDetail?.categoryConstruction,
        code: data?.idCons,
        actualPhoto: imageActual,
        photo3d: image3d,
        photoId: imageId,
      },
    };

    console.log(newObj);

    Notiflix.Loading.circle("Đang chỉnh sửa công trình...");
    editDetailConstruct(newObj)
      .then((res) => {
        Notiflix.Notify.success("Chỉnh sửa công trình thành công");
        Notiflix.Loading.remove();
        handleCancelEditConsDetail();
        refetch();
      })
      .catch((err) => {
        Notiflix.Notify.failure("Chỉnh sửa công trình thất bại");
        console.log(err);
      });
  };

  const handleViewCons = async (data) => {
    if (!data.selectArr) {
      setIsModalViewConsOpen(false);
      return;
    }
    Notiflix.Loading.circle("Đang gán tài khoản...");

    const user = data.selectArr.filter(
      (item) => item.value !== consSelect.user
    );

    const newObj = {
      idCons: consSelect._id,
      consDefault: data.consDefault,
      user,
    };

    await editConstruct(newObj)
      .then((res) => {
        Notiflix.Notify.success("Gán tài khoản thành công");
        Notiflix.Loading.remove();
        refetch();
      })
      .catch((err) => {
        Notiflix.Notify.failure("Gán tài khoản thất bại");
        Notiflix.Loading.remove();
        console.log(err);
      });
    setIsModalViewConsOpen(false);
    methods.reset();
  };

  const handleCancelEditConsDetail = () => {
    dispatch(setModalCons({ isModalEditConsOpen: false, consDetail: {} }));
    methods.reset();
  };

  const handleCancel = () => {
    dispatch(setModalCons({ isModalEditConsOpen: false }));
    setIsModalOpen(false);
    setConsSelect(null);
    setIsModalViewConsOpen(false);
    methods.reset();
  };

  const handleCardClick = async (cons) => {
    const image1 = unwrapResult(
      await dispatch(getImgCons({ imageName: cons.actualPhoto }))
    );
    const image2 = unwrapResult(
      await dispatch(getImgCons({ imageName: cons.photo3d }))
    );
    const image3 = unwrapResult(
      await dispatch(getImgCons({ imageName: cons.photoId }))
    );
    setImage([image1, image2, image3]);
    setConsSelect(cons);
    setIsModalViewConsOpen(true);
  };

  const openModalAddCons = () => {
    setIsModalOpen(true);
  };

  const handleSearch = (search) => {
    searchParams = search.search;
    refetch();
  };

  const handleFilter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("da click");
  };

  const filterUniqueItems = (items) => {
    const uniqueItems = new Map();
    items.forEach((item) => {
      if (!uniqueItems.has(item._id)) {
        uniqueItems.set(item._id, item);
      }
    });
    return Array.from(uniqueItems.values());
  };

  const processedData = data?.pages
    ? filterUniqueItems(data.pages.flatMap((pageData) => pageData))
    : [];

  return (
    <div className="body-content-cons">
      <form className="cons-header" onSubmit={handleSubmit(handleSearch)}>
        <div className="cons-text-contain">
          <span style={{ fontWeight: 600 }}>DANH SÁCH CÔNG TRÌNH</span>
        </div>
        <div className="search-container">
          <div className="input-search-container">
            <Input
              refetch={refetch}
              isFilter={true}
              handleFilter={handleFilter}
              className="input-cons"
              register={register}
              placeholder="Tìm kiếm mã công trình"
              name="search"
            />
          </div>
          {/* <div className="btn-search-container">
            <ButtonAdd className="btn-add-cons" onClick={() => { }}>
              Tìm kiếm
            </ButtonAdd>
          </div> */}
        </div>
      </form>
      <div
        onScroll={handleScroll}
        ref={tableRef}
        className={`card-list-container card-list-container--start`}
      >
        {processedData.map((cons, index) => (
          <div
            className="card-container-cons"
            key={cons?._id}
            onClick={() => handleCardClick(cons)}
          >
            <CustomCard
              refetch={refetch}
              data={data}
              cons={cons}
              index={cons?._id}
              handleCardClick={() => handleCardClick(cons)}
            />
          </div>
        ))}
        <div className="cons-button-add">
          <ButtonAdd onClick={openModalAddCons}>Thêm công trình</ButtonAdd>
        </div>
      </div>
      <FormProvider {...methods}>
        {isModalOpen && (
          <ModalAddCons
            isModalOpen={isModalOpen}
            handleOk={handleAddCons}
            handleCancel={handleCancel}
          />
        )}
        {consSelect && listUsersCons && (
          <ModalViewCons
            cons={consSelect}
            imageCons={image}
            listUsersCons={listUsersCons}
            isModalOpen={isModalViewConsOpen}
            handleOk={handleViewCons}
            handleCancel={handleCancel}
          />
        )}
        {isModalEditConsOpen && (
          <ModalEditCons
            key={consDetail?._id}
            handleOk={handleEditCons}
            handleCancel={handleCancelEditConsDetail}
          />
        )}
      </FormProvider>
    </div>
  );
}

export default ConsMan;
