import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: [
    {
      name: "Tường nhà",
      key: "tuong_nha",
      id: "6602510db4c8636b0e8345e9",
      color: "",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Phào nhà",
      color: "",
      key: "phao_nha",
      id: "6602510db4c8636b0e8345ea",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Chỉ nhà",
      color: "",
      key: "chi_nha",
      id: "6602510db4c8636b0e8345eb",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Cột nhà",
      color: "",
      key: "cot_nha",
      id: "6602510db4c8636b0e8345ec",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Chữ A",
      color: "",
      key: "chu_a",
      id: "6602510db4c8636b0e8345ed",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Nhấn",
      color: "",
      key: "nhan_nha",
      id: "6602510db4c8636b0e8345ee",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Con sơn",
      color: "",
      key: "con_son",
      id: "6602510db4c8636b0e8345ef",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Tổ mối",
      color: "",
      key: "to_moi",
      id: "6602510db4c8636b0e8345f0",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Lan can",
      color: "",
      key: "lan_can",
      id: "6602510db4c8636b0e8345f1",
      brand: "Hãng",
      colorCode: "",
    },
    {
      name: "Dàn mưa",
      color: "",
      key: "dan_mua",
      id: "6602510db4c8636b0e8345f2",
      brand: "Hãng",
      colorCode: "",
    },
  ],
  currentChange: [],
};

const color3dSclice = createSlice({
  name: "color3dSlice",
  initialState,
  reducers: {
    changeColor: (state, action) => {
      const index = state.currentChange.findIndex((value) => {
        return action.payload.name === value.name;
      });
      state.currentChange[index].colorCode = action.payload.colorCode;
      state.currentChange[index].color = action.payload.color;
      state.currentChange[index].brand = action.payload.brand;
    },
    getcolorBy3d: (state, action) => {
      const result = state.current.filter((value) =>
        action.payload.includes(value.key)
      );
      state.currentChange = result;
    },
  },
});

export const listValueColor3d = (state) => state.color3dSlice.currentChange;

export const { changeColor, getcolorBy3d } = color3dSclice.actions;
export default color3dSclice.reducer;
