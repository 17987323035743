import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import { Tabs, Image } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo_color.png";
import { ButtonLogout } from "../Button/Button";
import { useDispatch } from "react-redux";
import { removeToken } from "../../utils/localStorage";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { getAdmin } from "../../services/authServices";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("1");
  const [modalLogout, setModalLogout] = useState(false);
  const [user, setUser] = useState({});
  const [items, setItems] = useState([
    {
      key: "1",
      label: "Tài khoản",
    },
    {
      key: "2",
      label: "Công trình",
    },
    {
      key: "3",
      label: "Hệ thống màu sắc",
    },
  ]);
  useEffect(() => {
    getAdmin().then((res) => setUser(res.data));
  }, []);

  const handleChangeTab = (key) => {
    if (key === "1") {
      navigate("/");
      setActiveKey("1");
    }
    if (key === "2") {
      navigate("/constructor");
      setActiveKey("2");
    }
    if (key === "3") {
      navigate("/company");
      setActiveKey("3");
    }
  };
  const handleLogo = () => {
    navigate("/");
    setActiveKey("1");
  };
  const handleLogout = () => {
    removeToken();
    window.location.reload();
  };

  return (
    <div className="navbar-container">
      <div className="navbar">
        <div className="logo-container" onClick={handleLogo}>
          <div className="logo">
            <Image width={200} height={63} src={logo} preview={false} />
          </div>
        </div>
        <div className="tabs">
          <Tabs
            activeKey={activeKey}
            items={items}
            size="large"
            renderTabBar={(props, DefaultTabBar) => (
              <DefaultTabBar {...props}>
                {(node) => (
                  <div className="tab">
                    <span className="text-tab">{node}</span>
                  </div>
                )}
              </DefaultTabBar>
            )}
            onChange={(value) => handleChangeTab(value)}
          />
        </div>
        <div
          className="logoutContainer"
          onClick={() => setModalLogout(!modalLogout)}
        >
          <button className="logout">
            <span>{user?.role === "ADMIN" ? "CẤP 1" : "CẤP 2"}</span>
            {modalLogout ? (
              <span className="downIcon">
                <IoIosArrowDown />
              </span>
            ) : (
              <span className="upIcon">
                <IoIosArrowUp />
              </span>
            )}
          </button>
          {modalLogout && (
            <div className="modalLogout" onClick={handleLogout}>
              <div className="childList">
                <span className="childItem">Đăng xuất</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
