import { createSlice } from "@reduxjs/toolkit";
import { getUserInfor } from "../auth/authThunk";
import { set } from "react-hook-form";

const initialState = {
  user: null,
  logoSubmit: null,
  userSelected: null,
  reRender: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogoSubmit: (state, action) => {
      state.logoSubmit = action.payload;
    },
    setUserSelected: (state, action) => {
      console.log("action.payload", action.payload);
      state.userSelected = action.payload;
    },
    setReRender: (state, action) => {
      state.reRender = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfor.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const userSelector = (state) => state.user.user;
export const userSelectedSelector = (state) => state.user.userSelected;
export const reRenderSelector = (state) => state.user.reRender;

export const { setLogoSubmit, setUserSelected, setReRender } = userSlice.actions;
export default userSlice.reducer;
