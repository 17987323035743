import { useCallback, useEffect, useState, useRef } from "react";
import "./Upload.scss";
import { Image } from "antd";
import { useDispatch } from "react-redux";
import { setLogoSubmit, setReRender } from "../../store/user/userSlice";
import { setImgActual, setImg3d, setImgId } from "../../store/cons/consSlice";
import { useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { delLogoUser } from "../../services/imageServices";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { setChangenamelogo } from "../../store/logoUser/logoUserSlice";
import Notiflix from "notiflix";

export const Upload = ({
  refetch,
  text,
  name,
  imageCons,
  unChoose,
  isCons,
  imageLogo,
  register,
  isLogo,
  isDel,
  imageName,
  logoname,
}) => {
  const { logoSubmit } = useSelector((state) => state.user);
  const logoUsers = useSelector((state) => state.logoUser);

  useEffect(() => {
    setFile(null);
  }, [logoSubmit]);

  const [file, setFile] = useState(null);
  const [nameLogo, setNameLogo] = useState(logoname);
  const [visibleInputChangeLogo, setVisibleInputChangeLogo] = useState(false);
  const [visibleNameLogo, setVisibleNameLogo] = useState(true);

  const inputChangeLogoRef = useRef();

  const dispatch = useDispatch();
  const handleChange = useCallback(
    (file) => {
      // Check if the file is actually a Blob
      if (file && file instanceof Blob) {
        if (isCons) {
          if (name === "image1") {
            dispatch(setImgActual(file));
          } else if (name === "image2") {
            dispatch(setImg3d(file));
          } else {
            dispatch(setImgId(file));
          }
        } else {
          dispatch(setLogoSubmit(file));
        }

        const reader = new FileReader();
        reader.onload = () => {
          setFile(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        console.error("The selected file is not a Blob.");
      }
    },
    [dispatch, isCons, name]
  );

  const handleDelLogo = (e) => {
    e.preventDefault();
    delLogoUser(imageName)
      .then(() => {
        Notiflix.Notify.success("Xoá logo thành công!");
        // console.log("da vao ham refetch", refetch());
        return refetch();
      })
      .catch(() => {
        Notiflix.Notify.failure("Xoá logo thất bại");
      });
  };

  // xử lý thay đổi value input name logo
  const hanelChangenameLogo = (e) => {
    setNameLogo(e.target.value);
  };
  // xử lý hiển thị input thay đổi name logo
  const handleShowIputChangeLogo = () => {
    setVisibleInputChangeLogo(true);
    setVisibleNameLogo(false);
    setTimeout(() => {
      inputChangeLogoRef?.current?.focus();
    }, 100);
  };
  // xử lý cancle input thay đổi name logo
  const handlcancleChangeLogo = () => {
    setVisibleInputChangeLogo(false);
    setVisibleNameLogo(true);
  };
  // xử lý submit input thay đổi name logo
  const handleCheckChangeLogo = () => {
    setVisibleInputChangeLogo(false);
    setVisibleNameLogo(true);
    dispatch(
      setChangenamelogo({
        nameLogo: nameLogo,
        nameFile: imageName,
      })
    );
  };
  // console.log(logoUsers);

  return (
    <div>
      {file && (
        <div className="boxIcon">
          <Image
            loading="lazy"
            src={file}
            alt="upload"
            style={{ cursor: "pointer", border: "1px solid" }}
            width={220}
            height={150}
          />
          <div className="deleteIconBtn" onClick={() => setFile(null)}>
            <span className="deleteIcon">
              <RiDeleteBinLine />
            </span>
          </div>
        </div>
      )}
      {imageCons && !file && (
        <Image
          loading="lazy"
          src={imageCons}
          alt=" upload"
          style={{ cursor: "pointer", border: "1px solid" }}
          width={220}
          height={150}
        />
      )}
      {imageLogo && !file && (
        <div className="boxIcon">
          <Image
            loading="lazy"
            preview={false}
            src={imageLogo}
            alt=" upload"
            style={{ cursor: "pointer", border: "1px solid" }}
            width={270}
            height={150}
          />
          <div className="nameIconBox">
            {visibleNameLogo && (
              <div
                className="nameIcon"
                onClick={(e) => handleShowIputChangeLogo()}
              >
                {nameLogo}
              </div>
            )}

            {visibleInputChangeLogo && (
              <div className="changeNameIconBox">
                <input
                  className="nameIconInput"
                  type="text"
                  value={nameLogo}
                  onChange={hanelChangenameLogo}
                  ref={inputChangeLogoRef}
                />
                <div className="submitBox">
                  <span onClick={handlcancleChangeLogo}>X</span>
                  <span onClick={handleCheckChangeLogo}>
                    <FaCheck />
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="deleteIconBtn" onClick={handleDelLogo}>
            <span className="deleteIcon">
              <RiDeleteBinLine />
            </span>
          </div>
        </div>
      )}
      {((isLogo && !file) || (register && !file)) && (
        <FileUploader
          type="file"
          name={name}
          types={["JPG", "PNG", "GIF"]}
          children={<></>}
          classes="upload-container"
          hoverTitle="Thả ảnh vào đây"
          onDrop={(file) => handleChange(file)}
          onSelect={(file) => handleChange(file)}
        />
      )}
      <div className="d-flex pt-1 justify-content-center">
        <span className="upload-text">{text}</span>
      </div>
    </div>
  );
};
