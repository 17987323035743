import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const logoUserSlice = createSlice({
   name: "logoUser",
   initialState,
   reducers: {
      setChangenamelogo: (state, action) => {
         state.push(action.payload);
      },
      resetLogoUserChange: (state, action) => {
         return [];
      },
   },
});

export const { setChangenamelogo, resetLogoUserChange } = logoUserSlice.actions;
export default logoUserSlice.reducer;
