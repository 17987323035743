import { FaAngleDown, FaAngleLeft, FaArrowLeft } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import "./solution.scss";
import { Button } from "../../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  changeColor,
  getcolorBy3d,
  listValueColor3d,
} from "../../../../../store/solution/color3dSclice";
import { useEffect, useState } from "react";
import { userSelector } from "../../../../../store/user/userSlice";
import Notiflix from "notiflix";
import axios from "axios";
import Compressor from "compressorjs";
import { getListPaintsTest } from "../../../../../services/paintServices";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_ENDPOINT,
  API_ENDPOINT_AI,
} from "../../../../../services/axiosClient";
import {
  getBrandPaints,
  searchBrandPaintApi,
} from "../../../../../services/brandPaintServices";
import { useDebounce } from "@uidotdev/usehooks";

function Solution() {
  const location = useLocation();
  const navigate = useNavigate();
  const contruction = location.state?.cons;
  const [brandselect, setBrandSelect] = useState();
  const [selectItem, setSelectItem] = useState(null);
  const [brandColor, setBrandColor] = useState([]);
  const [serchColor, setSearchColor] = useState("");
  const debouncedSearchTerm = useDebounce(serchColor, 1000);
  const [imgBase64, setImgBase64] = useState(null);
  const [isShowColorMobile, setIsShowColorMobile] = useState(false);
  const user = useSelector(userSelector);
  const imageHome = document.querySelector(".imageHome");
  const dispath = useDispatch();
  const listContai = useSelector(listValueColor3d);

  const listContaiSelector = listContai.filter((value) => {
    return value.color.length > 1;
  });

  const [searchBrandPaint, setSearchBrandPaint] = useState(""); // State tìm kiếm hãng sơn
  const [brandNameSelect, setBrandNameSelect] = useState(""); // Tên hãng sơn được chọn

  useEffect(() => {
    const searchDebounce = () => {
      if (debouncedSearchTerm) {
        const params = {
          page: 1,
          pageSize: 100,
          search: debouncedSearchTerm,
        };
        searchBrandPaintApi(params, brandselect._id).then((res) => {
          setBrandSelect({ ...brandselect, paint: res.data.data });
        });
      } else {
        handleGetBrand({
          page: 1,
          pageSize: 100,
        });
      }
    };
    searchDebounce();
  }, [debouncedSearchTerm, imageHome?.clientWidth]);

  useEffect(() => {
    if (contruction?.actualPhoto) {
      Notiflix.Loading.pulse();
      axios
        .get(
          `https://ai.aicolor.vn/find_components/${contruction?.actualPhoto}`
        )
        .then((res) => {
          dispath(getcolorBy3d(res.data));
          Notiflix.Loading.remove();
        })
        .catch((err) => Notiflix.Loading.remove());
    }
  }, [contruction]);

  const listPaints = async (value) => {
    await getListPaintsTest(value._id).then((res) => {
      const newBrandSelect = {
        ...value,
        paint: res.data.data,
      };
      setBrandSelect(newBrandSelect);
    });
  };

  const handleSelectBrand = (value) => {
    setSearchBrandPaint("");
    setBrandNameSelect(value.name);
    if (user) {
      setBrandSelect(value);
    } else {
      listPaints(value);
    }
  };

  const handleSelectColor = (value) => {
    if (selectItem && contruction.actualPhoto) {
      const data = {
        ...selectItem,
        name: selectItem.name,
        color: value.rgb,
        colorCode: value.hex,
        brand: brandselect.name,
      };
      setSelectItem(data);
      dispath(changeColor(data));
    } else {
      Notiflix.Notify.warning("Bạn chưa chọn công trình để tô màu");
    }
  };

  const handleGetBrand = (params) => {
    getBrandPaints()
      .then((res) => {
        setBrandColor(res.data.data);
        handleSelectBrand(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetBrand({
      page: 1,
      pageSize: 100,
    });
  }, []);

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      if (serchColor.length > 0) {
        const params = {
          page: 1,
          pageSize: 100,
          search: serchColor,
        };
        searchBrandPaintApi(params, brandselect._id).then((res) => {
          setBrandSelect({ ...brandselect, paint: res.data.data });
        });
      } else {
        handleGetBrand({
          page: 1,
          pageSize: 100,
        });
      }
    }
  };

  async function base64ToFile(base64Data, filename) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], filename, { type: "image/png" });
    const resizeFile = () =>
      new Compressor(file, {
        quality: 0.8,
        width: 2000,
        height: 2000,
        mimeType: "image/png",
        success(result) {},
        error(err) {
          console.log(err.message);
        },
      });
    resizeFile();
    return file;
  }

  function base64ToBlob(base64, mime) {
    mime = mime || "";
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }

  const handleSubmitPaint = async () => {
    const formData = new FormData();
    formData.append("image", contruction.photo3d);

    listContaiSelector.forEach((element) => {
      formData.append(element.key, element.color.replace(/rgb|\(|\)/g, ""));
    });
    if (listContaiSelector.length >= 1 && contruction.actualPhoto) {
      Notiflix.Loading.pulse();
      axios
        .post(API_ENDPOINT_AI, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          Notiflix.Loading.remove();
          setImgBase64(res.data);
        })
        .catch((err) => {
          Notiflix.Loading.remove();
        });
    } else {
      Notiflix.Notify.warning("Công trình chưa hoàn thiện");
    }
  };

  const filteredBrandColor = brandColor.filter((value) =>
    value.name.toLowerCase().includes(searchBrandPaint.toLowerCase())
  );

  return (
    <div className="solution">
      <div className="solution-left">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            padding: 10,
            marginLeft: 30,
            cursor: "pointer",
            width: "max-content",
          }}
          onClick={() => navigate("/constructor")}
        >
          <FaArrowLeft style={{ fontSize: 16 }} />
          <span style={{ fontSize: 20, fontWeight: "bold" }}> Quay lại</span>
        </div>
        <div className="solution-left-top">
          {contruction && (
            <div
              className="listContai"
              style={{ width: imageHome?.clientWidth }}
            >
              {listContai.map((value, index) => (
                <div
                  className="itemContai"
                  key={index}
                  onClick={(e) => {
                    setIsShowColorMobile(true);
                    setSelectItem(value);
                  }}
                  style={{
                    opacity: `${selectItem?.name == value.name ? 1 : 0.7}`,
                  }}
                >
                  <div
                    className="round"
                    style={{
                      backgroundColor: `${
                        value.color ? value.color : "#D9D9D9"
                      }`,
                    }}
                  ></div>
                  <div className="info">
                    <div className="contai">{value.name}</div>
                    <div className="brand">
                      <span>{value.brand}</span> <span>-</span>
                      <span>
                        {value.colorCode?.length > 1
                          ? value.colorCode
                          : "mã màu"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="imageHome">
            {imgBase64 ? (
              <img src={`data:image/png;base64,${imgBase64}`} alt="" />
            ) : contruction?.actualPhoto ? (
              contruction?.image ? (
                <img
                  src={`${API_ENDPOINT}image/storage/${contruction.image}`}
                />
              ) : (
                <img
                  src={`${API_ENDPOINT}image/constructions/${contruction.photo3d}`}
                />
              )
            ) : (
              <span>Vui lòng chọn công trình!</span>
            )}
          </div>
        </div>
        <div className="solution-left-bottom">
          <div className="box">
            <Button content="Xác nhận" onClick={handleSubmitPaint} />
          </div>
        </div>
      </div>
      <div className={`solution-right ${isShowColorMobile ? "show" : ""}`}>
        <div className="solution-right-top" style={{ overflowY: "hidden" }}>
          <div className="heading">Phối màu sơn</div>
          <div className="selects">
            <div className="selects-brand">
              <input
                type="text"
                style={{
                  border: "none",
                  fontSize: 24,
                  height: "auto",
                  outline: "none",
                  fontSizeAdjust: "20px",
                  fontWeight: 500,
                  marginLeft: "16px",
                }}
                value={searchBrandPaint}
                placeholder={brandNameSelect}
                onChange={(e) => {
                  setSearchBrandPaint(e.target.value);
                }}
              />
              <span>
                <FaAngleDown style={{ fontSize: 16 }} />
              </span>
              <ul className="dropdown">
                {filteredBrandColor.map((value, index) => (
                  <li key={index} onClick={(e) => handleSelectBrand(value)}>
                    {value.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="selects-color">
              <span>
                <IoSearch />
              </span>
              <input
                type="text"
                value={serchColor}
                onChange={(e) => setSearchColor(e.target.value)}
                onKeyDown={handleSearch}
                placeholder="Tìm kiếm mã màu"
              />
            </div>
          </div>
          <div className="listColor">
            {brandselect?.paint.map((item, index) => (
              <div
                key={index}
                className={`itemColor ${
                  item.rgb == "rgb(255,255,255)" ? "white" : ""
                }`}
                style={{ backgroundColor: `${item.rgb}` }}
                onClick={(e) => {
                  handleSelectColor(item);
                  setIsShowColorMobile(false);
                }}
              >
                <span>{item.hex}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="solution-right-bottom">
          <Button
            onClick={handleSubmitPaint}
            style={{
              padding: 0,
              fontSize: "14px",
              width: "15%",
            }}
          >
            Xác nhận
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Solution;
