import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MenuSelectionCons.scss";
import { deleteConstruct } from "../../../../../services/constructServices";
import Notiflix from "notiflix";
import { useDispatch } from "react-redux";
import { setModalCons } from "../../../../../store/modal/modalSlice";

const listRouter = [
  {
    name: ". . .",
    child: [
      {
        name: "Chỉnh sửa",
      },
      {
        name: "Xem trước",
      },
      {
        name: "Xóa công trình",
      },
    ],
  },
];

const MenuSelectCons = ({ cons, isDetail, refetch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectOption = (e, value) => {
    e.stopPropagation();
    if (value.name === "Xem trước") {
      navigate("/solution", { state: { cons } });
    } else if (value.name === "Chỉnh sửa") {
      dispatch(setModalCons({
        isModalEditConsOpen: true,
        consDetail: cons,
      }))
    } else {
      deleteConstruct(cons._id)
        .then(() => {
          Notiflix.Notify.success("Xóa công trình thành công !!!");
          refetch();
        })
        .catch(() => Notiflix.Notify.failure("Xóa công trình Thất bại !!!"));
    }
  };

  return (
    <ul className={`listNav ${isDetail ? "Detail" : ""}`}>
      {listRouter.map((value, index) => (
        <li className={`itemNav`} key={index}>
          <span>{value.name}</span>
          {value.child && (
            <div className="navChild">
              <ul className="childList">
                {value.child.map((value, index) => (
                  <li
                    className="childItem"
                    key={index}
                    onClick={(e) => {
                      handleSelectOption(e, value);
                    }}
                  >
                    {value.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MenuSelectCons;
