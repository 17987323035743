import React, { useEffect, useId, useRef, useState } from "react";
import "./Input.scss";
import { Flex, Image, Modal, Select, Tag } from "antd";
import filterIcon from "../../assets/filterIcon.png";
import filterIconHover from "../../assets/filterIconHover.png";
import { useHover } from "@uidotdev/usehooks";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { getCategoryCons } from "../../services/categoryConstructionServices";
import { getUsers } from "../../services/userServices";
import { getCons } from "../../services/constructServices";
import { useDispatch, useSelector } from "react-redux";
import { setFilterCons } from "../../store/cons/consSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"#3B54A5"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        color: "white",
        fontSize: "15px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        margin: "5px 5px",
        height: "30px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
    </Tag>
  );
};

export const Input = ({
  type,
  text,
  register,
  placeholder,
  name,
  required,
  pattern,
  minLength,
  value,
  setFocus,
  defaultValue,
  isIcon,
  handleFilter,
  isFilter,
  refetch,
  disabled,
  isShowPassword,
  setIsShowPassword,
  ...props
}) => {
  const idInput = useId();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [ref, hovering] = useHover();
  const [categoryCons, setCategoryCons] = useState([]);
  const [users, setUsers] = useState([]);
  const [cateConsResult, setCateConsResult] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const dispath = useDispatch();
  const [filterResult, setFilterResult] = useState("");
  useEffect(() => {
    getCategoryCons().then((res) => setCategoryCons(res.data.data));
    getUsers().then((res) => setUsers(res.data.data));
  }, []);

  const optionsUsers = users?.map((item) => ({
    label: item.fullName,
    value: item._id,
  }));

  const optionsCateCons = categoryCons?.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const handleChangeCateCons = (value) => {
    setCateConsResult(value);
  };

  const handleChangeUsers = (value) => {
    setUserResult(value);
  };

  const handleCheckbox = () => {
    setIsCheckbox(!isCheckbox);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubmit = () => {
    const data = {
      user: userResult,
      categoryConstruction: cateConsResult,
      consDefault: isCheckbox,
    };
    setFilterResult(data);
    dispath(setFilterCons(data));
    setIsCheckbox(false);
    setCateConsResult([]);
    setUserResult([]);
    refetch();
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
    refetch();
    dispath(setFilterCons([]));
    setIsCheckbox(false);
    setCateConsResult([]);
    setUserResult([]);
    setFilterResult("");
  };

  const toggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <div className="input-field" {...props}>
      <span>{text}</span>
      <div
        className="container-input"
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "5px",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          padding: "0px 10px 0 0px",
        }}
      >
        <input
          disabled={disabled}
          defaultValue={defaultValue}
          id={idInput}
          value={value}
          type={isShowPassword ? "text" : type}
          placeholder={placeholder}
          {...register(name, {
            required: required ?? null,
            pattern: pattern ?? null,
            minLength: minLength ?? null,
          })}
        />
        {type === "password" && (
          <div onClick={toggleShowPassword} style={{ cursor: "pointer" }}>
            {isShowPassword ? <FaEye size={25} /> : <FaEyeSlash size={25} />}
          </div>
        )}
      </div>

      {isFilter && (
        <div
          ref={ref}
          className="buttonFilterContainer"
          onClick={openModal}
          // onMouseLeave={closeModal}
        >
          <div className="buttonFilter">
            {isModalOpen || filterResult ? (
              <Image
                // onClick={() => console.log('asdasd')}
                className="buttonFilter"
                src={filterIconHover}
                preview={false}
                width={30}
                height={30}
              />
            ) : (
              <Image
                // onClick={() => console.log('asdasd')}
                src={filterIcon}
                preview={false}
                width={30}
                height={30}
              />
            )}
          </div>
        </div>
      )}
      {isFilter && isModalOpen && (
        <div
          className="modalFilterContainer"
          onMouseEnter={openModal}
          onMouseLeave={closeModal}
        >
          <div className="itemSelect">
            <span className="titleItemSelect">Danh mục công trình</span>
            <Select
              disabled={isCheckbox}
              dropdownRender={(menu) => {
                return (
                  <div
                  // onMouseEnter={openModal}
                  // onMouseLeave={closeModal}
                  >
                    {menu}
                  </div>
                );
              }}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              tagRender={tagRender}
              placeholder="Chọn công trình"
              dropdownStyle={{ zIndex: 10000 }}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleChangeCateCons}
              options={optionsCateCons}
            />
          </div>
          <div className="itemSelect">
            <span className="titleItemSelect">Khách hàng</span>
            <Select
              disabled={isCheckbox}
              dropdownRender={(menu) => {
                return (
                  <div
                  // onMouseEnter={openModal}
                  // onMouseLeave={closeModal}
                  >
                    {menu}
                  </div>
                );
              }}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              tagRender={tagRender}
              dropdownStyle={{ zIndex: 10000 }}
              placeholder="Chọn khách hàng"
              onChange={handleChangeUsers}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionsUsers}
            />
          </div>
          <div className="itemCheckbox">
            <span className="titleItemCheckbox">Công trình mẫu</span>
            <div className="iconCheckbox" onClick={handleCheckbox}>
              {userResult.length > 0 || cateConsResult.length > 0 ? (
                <></>
              ) : isCheckbox ? (
                <RiCheckboxCircleLine size={25} />
              ) : (
                <RiCheckboxBlankCircleLine size={25} />
              )}
            </div>
          </div>
          <div className="itemButton">
            <button
              type="button"
              className="buttonCancel"
              onClick={handleCancel}
            >
              <span className="contentButton">Hủy</span>
            </button>

            {(isCheckbox ||
              cateConsResult.length > 0 ||
              userResult.length > 0) && (
              <button
                type="button"
                className="buttonFilter"
                onClick={handleSubmit}
              >
                <span className="contentButton">Lọc</span>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
