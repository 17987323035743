import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isModalEditOpen: false,
	modalCons: {
		isModalEditConsOpen: false,
		consDetail: {},
	}
};

const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		setModalEditOpen: (state, action) => {
			state.isModalEditOpen = action.payload;
		},
		setModalCons: (state, action) => {
			// Chỉ cập nhật các trường cần thiết thay vì gán lại toàn bộ modalCons
			const { isModalEditConsOpen, consDetail } = action.payload;
			if (typeof isModalEditConsOpen !== 'undefined') {
				state.modalCons.isModalEditConsOpen = isModalEditConsOpen;
			}
			if (consDetail) {
				state.modalCons.consDetail = consDetail;
			}
		}
	},
});

export const isModalEditOpenSelector = (state) => state.modal.isModalEditOpen;
export const modalConsSelector = (state) => state.modal.modalCons;

export const { setModalEditOpen, setModalCons } = modalSlice.actions;
export default modalSlice.reducer;
