import React, { memo, useEffect, useState } from "react";
import { Modal, Button, Divider, Flex, Radio } from "antd";
import { Input } from "../Input/Input";
import { Upload } from "../Upload/Upload";
import "./CustomModal.scss";
import { useFormContext, Controller } from "react-hook-form";
import { Select, Space, Tag } from "antd";
import MenuSelectCons from "../../pages/Tab/ConsMan/components/MenuSelectCons/MenuSelectCons";
import {
  reRenderSelector,
  userSelectedSelector,
} from "../../store/user/userSlice";
import { getCategoryCons } from "../../services/categoryConstructionServices";
import { useSelector } from "react-redux";
import { modalConsSelector } from "../../store/modal/modalSlice";
import _ from "lodash";
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
} from "react-icons/ri";

// {/* Modal tạo user */}
export const ModalForm = ({
  isModalOpen,
  handleOk,
  handleCancel,
  confirmLoading,
  listPaints,
}) => {
  const options = listPaints?.map((paint) => ({
    label: paint?.name,
    value: paint?._id,
  }));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Modal
        afterClose={handleCancel}
        style={{ fontFamily: "Montserrat" }}
        centered
        confirmLoading={confirmLoading}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={
          <Flex vertical>
            <Divider />
            <Flex gap={20} justify="end">
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  color: "white",
                  backgroundColor: "#d9d9d9",
                  borderColor: "#d9d9d9",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
              >
                Hủy
              </Button>
              <Button
                key="submit"
                style={{
                  color: "white",
                  backgroundColor: "#3B54A5",
                  borderColor: "#3B54A5",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
                onClick={handleSubmit(handleOk)}
              >
                Tạo
              </Button>
            </Flex>
          </Flex>
        }
      >
        <form className="custom-modal">
          <div className="title-modal">
            <span className="title-modal-text">Thêm tài khoản</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="modal-user-name">
              <Input
                text={"Họ và tên"}
                type={"text"}
                placeholder={"Nhập họ và tên"}
                register={register}
                required={{
                  value: true,
                  message: "Họ và tên không được để trống",
                }}
                name={"fullName"}
              />
              {errors.fullName && (
                <span className="error-message">{errors.fullName.message}</span>
              )}
            </div>
            <div className="modal-user-phone">
              <Input
                type={"number"}
                text={"Số điện thoại"}
                placeholder={"Nhập số điện thoại"}
                register={register}
                required={{
                  value: true,
                  message: "Số điện thoại không được để trống",
                }}
                pattern={{
                  value: /^0[0-9]{0,9}$/,
                  message: "Số điện thoại không hợp lệ",
                }}
                name={"phone"}
              />
              {errors.phone && (
                <span className="error-message">{errors.phone.message}</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <Input
                text={"Mật khẩu"}
                type="password"
                placeholder={"Nhập mật khẩu"}
                register={register}
                required={{
                  value: true,
                  message: "Mật khẩu không được để trống",
                }}
                minLength={{
                  value: 6,
                  message: "Mật khẩu phải có ít nhất 6 kí tự",
                }}
                name={"password"}
              />
              {errors.password && (
                <span className="error-message">{errors.password.message}</span>
              )}
            </div>
            <div style={{ width: "30%" }}>
              <Input
                type="text"
                text={"Mã khách hàng"}
                placeholder={"Nhập mã khách hàng"}
                register={register}
                required={{
                  value: true,
                  message: "Mã khách hàng không được để trống",
                }}
                minLength={{
                  value: 2,
                  message: "Mã khách hàng phải có ít nhất 2 kí tự",
                }}
                name={"code"}
              />
              {errors.code && (
                <span className="error-message">{errors.code.message}</span>
              )}
            </div>
          </div>
          <div>
            <Input
              text={"Tên đại lý"}
              placeholder={"Nhập tên đại lý"}
              register={register}
              name={"agentName"}
            />
          </div>
          <div>
            <Input
              text={"Địa chỉ đại lý"}
              placeholder={"Nhập địa chỉ đại lý"}
              register={register}
              name={"address"}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Hãng sơn
            </span>
            <Controller
              name="brandPaint"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Select
                  {...field}
                  className="my-select"
                  mode="multiple"
                  style={{ height: "100%" }}
                  tagRender={tagRender}
                  optionLabelProp="label"
                  options={options}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  optionRender={(option) => <Space>{option.data.label}</Space>}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: "10px",
              width: "270px",
            }}
            className="image-upload-container"
          >
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Logo đại lý{" "}
            </span>
            <Upload
              unChoose={true}
              name={"logoAdd"}
              isCons={false}
              isLogo={true}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export const ModalEditForm = ({
  isModalOpen,
  handleOk,
  handleCancel,
  confirmLoading,
  listPaints,
  handleDelete,
  refetch,
}) => {
  const user = useSelector(userSelectedSelector);
  const reRender = useSelector(reRenderSelector);
  const [isShowPassword, setIsShowPassword] = useState(false);

  useEffect(() => {
    console.log("reRender modalEdit", reRender);
  }, [reRender]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useFormContext();

  const options = user?.brandPaint?.map((paint) => ({
    label: paint?.name,
    value: paint?._id,
  }));
  const optionsPaint = listPaints?.map((paint) => ({
    label: paint?.name,
    value: paint?._id,
  }));

  return (
    <>
      <Modal
        maskClosable={false}
        afterClose={handleCancel}
        centered
        confirmLoading={confirmLoading}
        open={isModalOpen}
        onCancel={handleCancel}
        width={1000}
        footer={
          <Flex vertical>
            <Divider />
            <Flex justify="end" gap={20}>
              <Button
                key="delete"
                onClick={handleDelete}
                style={{
                  position: "absolute",
                  left: 50,
                  color: "#3b54a5",
                  backgroundColor: "white",
                  borderColor: "#3b54a5",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
              >
                Xoá tài khoản
              </Button>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  color: "white",
                  backgroundColor: "#d9d9d9",
                  borderColor: "#d9d9d9",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
              >
                Hủy
              </Button>
              <Button
                key="submit"
                style={{
                  color: "white",
                  backgroundColor: "#3B54A5",
                  borderColor: "#3B54A5",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                }}
                onClick={handleSubmit(handleOk)}
              >
                Lưu lại
              </Button>
            </Flex>
          </Flex>
        }
      >
        <form className="custom-modal">
          <div className="title-modal">
            <span className="title-modal-text">Chỉnh sửa tài khoản</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="modal-user-name">
              <Input
                defaultValue={user?.fullName}
                text={"Họ và tên"}
                placeholder={"Nhập họ và tên"}
                register={register}
                required={{
                  value: true,
                  message: "Họ và tên không được để trống",
                }}
                name={"fullName"}
              />
            </div>
            <div className="modal-user-phone">
              <Input
                defaultValue={user?.phone}
                text={"Số điện thoại"}
                placeholder={"Nhập số điện thoại"}
                register={register}
                required={{
                  value: true,
                  message: "Số điện thoại không được để trống",
                }}
                pattern={{
                  value: /^0[0-9]{0,9}$/,
                  message: "Số điện thoại không hợp lệ",
                }}
                name={"phone"}
              />
              {errors.phone && (
                <span className="error-message">{errors.phone.message}</span>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "48%" }}>
              <Input
                isShowPassword={isShowPassword}
                setIsShowPassword={setIsShowPassword}
                defaultValue={user?.password}
                type="password"
                text={"Mật khẩu"}
                placeholder={"Nhập mật khẩu"}
                register={register}
                required={{
                  value: true,
                  message: "Mật khẩu không được để trống",
                }}
                minLength={{
                  value: 5,
                  message: "Mật khẩu phải có ít nhất 6 kí tự",
                }}
                name={"password"}
              />
              {errors.password && (
                <span className="error-message">{errors.password.message}</span>
              )}
            </div>
            <div style={{ width: "30%" }}>
              <Input
                defaultValue={user?.code}
                type="text"
                text={"Mã khách hàng"}
                placeholder={"Nhập mã khách hàng"}
                register={register}
                name={"code"}
              />
              {errors.code && (
                <span className="error-message">{errors.code.message}</span>
              )}
            </div>
            <div>
              <h6
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "calc(40px - 20px)",
                  fontWeight: 600,
                }}
              >
                Quyền tài khoản
              </h6>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={user?.role}
                    style={{
                      width: 170,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                    placeholder="USER"
                    register={register}
                    options={[
                      { value: "USER", label: "USER" },
                      { value: "SALE", label: "SALE" },
                      { value: "ADMIN", label: "ADMIN" },
                    ]}
                  />
                )}
              />
            </div>
          </div>
          <div>
            <Input
              defaultValue={user?.agentName}
              text={"Tên đại lý"}
              placeholder={"Nhập tên đại lý"}
              register={register}
              name={"agentName"}
            />
          </div>
          <div>
            <Input
              defaultValue={user?.address}
              text={"Địa chỉ đại lý"}
              placeholder={"Nhập địa chỉ đại lý"}
              register={register}
              name={"address"}
            />
          </div>
          <div
            className="modal-view-cons"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Hãng sơn đang kinh doanh
            </span>
            <Controller
              name="brandPaint"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="my-select"
                  mode="multiple"
                  style={{ height: "100%" }}
                  tagRender={tagRender}
                  placeholder=""
                  optionLabelProp="label"
                  defaultValue={options}
                  options={optionsPaint}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}></span>
                      {option.data.label}
                    </Space>
                  )}
                />
              )}
            />
          </div>
          <div>
            <Input
              defaultValue={user?.ortherBrandPaint}
              text={"Hãng sơn đang kinh doanh khác"}
              placeholder={"Nhập hãng sơn"}
              register={register}
              name={"ortherBrandPaint"}
            />
            {errors.ortherBrandPaint && (
              <span className="error-message">
                {errors.ortherBrandPaint.message}
              </span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: "10px",
              width: "220px",
            }}
            className="image-upload-container"
          >
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Logo đại lý{" "}
            </span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)", // 3 cột có chiều rộng bằng nhau
                gap: 10, // Khoảng cách giữa các thành phần
              }}
            >
              {user?.logo.map((item) => (
                <Upload
                  name={"logo"}
                  imageLogo={item.urlImage}
                  isCons={false}
                  key={item._id}
                  isDel={true}
                  imageName={item.nameFile}
                  logoname={item.nameLogo}
                  refetch={refetch}
                />
              ))}

              <Upload
                unChoose={true}
                name={"logoAdd"}
                isCons={false}
                isLogo={true}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export const ModalAddCons = ({
  isModalOpen,
  handleOk,
  handleCancel,
  confirmLoading,
}) => {
  const [categoryConstructions, setCategoryConstructions] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    getCategoryCons()
      .then((res) => setCategoryConstructions(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const options = categoryConstructions?.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  return (
    <>
      <Modal
        style={{ fontFamily: "Montserrat", overflowY: "auto" }}
        centered
        confirmLoading={confirmLoading}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            style={{
              color: "white",
              backgroundColor: "#d9d9d9",
              borderColor: "#d9d9d9",
              fontFamily: "Montserrat",
              fontWeight: "600",
            }}
          >
            Hủy
          </Button>,
          <Button
            key="submit"
            style={{
              color: "white",
              backgroundColor: "#3B54A5",
              borderColor: "#3B54A5",
              fontFamily: "Montserrat",
              fontWeight: "600",
            }}
            onClick={handleSubmit(handleOk)}
          >
            Lưu
          </Button>,
        ]}
      >
        <form className="custom-modal">
          <div className="title-modal">
            <span className="title-modal-text">Thêm công trình</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <div className="modal-cons-name">
              <div className="modal-view-cons" style={{ gap: 10 }}>
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Danh mục công trình
                </span>
                <Controller
                  name="selectCateCons"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="my-select"
                      style={{ height: "100%" }}
                      tagRender={tagRender}
                      placeholder="Chọn danh mục công trình"
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      options={options}
                      optionRender={(option) => (
                        <Space>{option.data.label}</Space>
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div className="modal-cons-id">
              <Input
                text={"Mã công trình"}
                placeholder={"Nhập mã công trình"}
                register={register}
                required={{
                  value: true,
                  message: "Mã công trình không được để trống",
                }}
                name={"idCons"}
              />
              {errors.idCons && (
                <span className="error-message">{errors.idCons.message}</span>
              )}
            </div>
          </div>
          <div>
            <span className="upload-title">Thêm hình ảnh</span>
            <div className="image-upload-container pt-2">
              <Upload
                unChoose={true}
                text={"Ảnh thực tế của công trình"}
                name={"image1"}
                register={register}
                isCons={true}
              />
              <Upload
                unChoose={true}
                text={"Ảnh 3D của công trình"}
                name={"image2"}
                register={register}
                isCons={true}
              />
              <Upload
                unChoose={true}
                text={"Ảnh ID của công trình"}
                name={"image3"}
                register={register}
                isCons={true}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"#3B54A5"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        color: "white",
        fontSize: "15px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        margin: "5px 5px",
        height: "30px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
    </Tag>
  );
};

export const ModalViewCons = memo(
  ({
    cons,
    isModalOpen,
    handleOk,
    handleCancel,
    confirmLoading,
    listUsersCons,
    imageCons,
  }) => {
    const users = cons?.user?.map((user) => user);
    const {
      handleSubmit,
      control,
      setValue,
      formState: { errors },
    } = useFormContext();
    const options = listUsersCons?.map((user) => ({
      label: user.fullName,
      value: user._id,
    }));

    const options1 = options?.filter(
      (option) => users.find((user) => user._id == option.value)?._id
    );
    // console.log("options1", options1);
    const [toggleConsDefault, settoggleConsDefault] = useState(
      cons.consDefault
    );

    const handleCheckboxClick = () => {
      const newValue = !toggleConsDefault;
      settoggleConsDefault(newValue);
      setValue("consDefault", newValue);
    };

    return (
      <>
        <Modal
          centered
          confirmLoading={confirmLoading}
          afterClose={handleCancel}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          style={{ overflow: "hidden", fontFamily: "Montserrat" }}
          footer={[
            <Button
              key="back"
              onClick={handleCancel}
              style={{
                color: "white",
                backgroundColor: "#d9d9d9",
                borderColor: "#d9d9d9",
                fontFamily: "Montserrat",
                fontWeight: "600",
              }}
            >
              Hủy
            </Button>,
            <Button
              key="submit"
              style={{
                color: "white",
                backgroundColor: "#3B54A5",
                borderColor: "#3B54A5",
                fontFamily: "Montserrat",
                fontWeight: "600",
              }}
              type="submit"
              onClick={handleSubmit(handleOk)}
            >
              Lưu
            </Button>,
          ]}
        >
          <form className="custom-modal" onSubmit={handleSubmit(handleOk)}>
            <div className="title-modal">
              <span className="title-modal-text">
                {cons?.code ? cons?.code : "#####"}
              </span>
              <MenuSelectCons cons={cons} isDetail={true} />
            </div>
            <div>
              <div className="image-upload-container pt-2">
                <Upload
                  text={"Ảnh thực tế của công trình"}
                  name={"image1"}
                  imageCons={imageCons[0]}
                  unChoose={true}
                />
                <Upload
                  text={"Ảnh 3D của công trình"}
                  name={"image2"}
                  imageCons={imageCons[1]}
                  unChoose={true}
                />
                <Upload
                  text={"Ảnh ID của công trình"}
                  name={"image3"}
                  imageCons={imageCons[2]}
                  unChoose={true}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="modal-view-cons">
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Gán làm công trình mẫu
                </span>
              </div>
              <div>
                <div className="itemCheckbox">
                  <Controller
                    name="consDefault"
                    control={control}
                    defaultValue={toggleConsDefault}
                    render={({ field }) => (
                      <div
                        {...field}
                        className="iconCheckbox"
                        onClick={handleCheckboxClick}
                      >
                        {toggleConsDefault ? (
                          <RiCheckboxCircleLine size={25} />
                        ) : (
                          <RiCheckboxBlankCircleLine size={25} />
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="modal-view-cons">
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Gán cho tài khoản
                </span>
                <Controller
                  name="selectArr"
                  control={control}
                  defaultValue={options1.map((item) => item.value)}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="my-select"
                      mode="multiple"
                      style={{ height: "100%" }}
                      tagRender={tagRender}
                      placeholder="Chọn tài khoản"
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      options={options}
                      optionRender={(option) => (
                        <Space>{option.data.label}</Space>
                      )}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </Modal>
      </>
    );
  }
);

export const ModalEditCons = memo(({ handleOk, handleCancel }) => {
  const { isModalEditConsOpen, consDetail } = useSelector(modalConsSelector);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [categoryCons, setCategoryCons] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();

  const options = consDetail?.categoryConstruction?.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const optionsChoose = categoryCons?.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  useEffect(() => {
    if (!_.isEmpty(consDetail)) {
      getCategoryCons()
        .then((res) => setCategoryCons(res.data.data))
        .catch((err) => console.log(err));
    }
    console.log("render ModalEditCons");
  }, []);

  // console.log(options);
  console.log(consDetail);

  return (
    <>
      <Modal
        key={consDetail?._id}
        style={{ fontFamily: "Montserrat", overflowY: "auto" }}
        centered
        confirmLoading={confirmLoading}
        open={isModalEditConsOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            style={{
              color: "white",
              backgroundColor: "#d9d9d9",
              borderColor: "#d9d9d9",
              fontFamily: "Montserrat",
              fontWeight: "600",
              height: 40,
              width: 70,
            }}
          >
            Hủy
          </Button>,
          <Button
            key="submit"
            style={{
              color: "white",
              backgroundColor: "#3B54A5",
              borderColor: "#3B54A5",
              fontFamily: "Montserrat",
              fontWeight: "600",
              height: 40,
              width: 70,
            }}
            onClick={handleSubmit(handleOk)}
          >
            Lưu
          </Button>,
        ]}
      >
        <form className="custom-modal" style={{ height: "500px" }}>
          <div className="title-modal">
            <span className="title-modal-text">Chỉnh sửa công trình</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <div className="modal-cons-name">
              <div className="modal-view-cons" style={{ gap: 10 }}>
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Danh mục công trình
                </span>
                <Controller
                  name="selectCateCons"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue={options}
                      options={optionsChoose}
                      className="my-select"
                      style={{ height: "100%" }}
                      tagRender={tagRender}
                      placeholder="Chọn danh mục công trình"
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      optionRender={(option) => (
                        <Space>{option.data.label}</Space>
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div className="modal-cons-id">
              <Input
                disabled={true}
                text={"Mã công trình"}
                defaultValue={consDetail?.code}
                placeholder={"Nhập mã công trình"}
                register={register}
                required={{
                  value: true,
                  message: "Mã công trình không được để trống",
                }}
                name={"idCons"}
              />
              {errors.idCons && (
                <span className="error-message">{errors.idCons.message}</span>
              )}
            </div>
          </div>
          <div>
            <span className="upload-title">Thêm hình ảnh</span>
            <div className="image-upload-container pt-2">
              <Upload
                unChoose={true}
                text={"Ảnh thực tế của công trình"}
                name={"image1"}
                register={register}
                isCons={true}
              />
              <Upload
                unChoose={true}
                text={"Ảnh 3D của công trình"}
                name={"image2"}
                register={register}
                isCons={true}
              />
              <Upload
                unChoose={true}
                text={"Ảnh ID của công trình"}
                name={"image3"}
                register={register}
                isCons={true}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
});
