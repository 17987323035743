import "./UserMan.scss";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { ButtonAdd, ButtonLogout } from "../../../components/Button/Button";
import {
  ModalForm,
  ModalEditForm,
  ModalNotification,
} from "../../../components/CustomModal/CustomModal";
import {
  useMutation,
  useInfiniteQuery,
  keepPreviousData,
  useQuery,
} from "@tanstack/react-query";
import { getUsers, getUsersForExcel } from "../../../services/userServices";
import { Input } from "../../../components/Input/Input";
import { FormProvider, set, useForm } from "react-hook-form";
import { Avatar, Badge, Divider, Flex, Modal, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  registerUser,
  editUser,
  deleteUser,
} from "../../../services/userServices";
import Notiflix from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import { getBrandPaints } from "../../../services/brandPaintServices";
import {
  setLogoSubmit,
  setUserSelected,
  userSelectedSelector,
  userSelector,
} from "../../../store/user/userSlice";

import handlers from "./UserManHandler/UserManHandler";
import spinner from "../../../assets/spinner.ico";
import _ from "lodash";
import { utils, writeFileXLSX } from "xlsx";
import { resetLogoUserChange } from "../../../store/logoUser/logoUserSlice";
import {
  isModalEditOpenSelector,
  setModalEditOpen,
} from "../../../store/modal/modalSlice";
import {
  getAllNotification,
  updateStatusNotification,
} from "../../../services/notificationServices";
import { socketOn } from "../../../services/socketServices";
import { NotiStatus } from "../../../utils/constants";
import { IoNotificationsSharp } from "react-icons/io5";
import { getAdmin } from "../../../services/authServices";

function UserMan() {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const logo = useSelector((state) => state.user.logoSubmit);
  const logoUsers = useSelector((state) => state.logoUser);
  const reload = useSelector((state) => state.reload);
  const userSelected = useSelector(userSelectedSelector);
  const isModalEditOpen = useSelector(isModalEditOpenSelector);

  console.log("userSelected line 45 file UserMan: ", userSelected);
  // const [userSelected, setUserSelected] = useState(null);

  const [logoUser, setLogoUser] = useState(null);
  // console.log("userSelected line 43 file UserMan: ", userSelected);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [user, setUser] = useState(null);
  const [meta, setMeta] = useState(null);

  let searchParams = "";
  const methods = useForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: listPaints } = useQuery({
    queryKey: ["listPaintUser"],
    queryFn: async () => {
      const response = await getBrandPaints();
      return response.data.data;
    },
  });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ["listUsers"],
      queryFn: async ({ pageParam = 1 }) => {
        if (!data) {
          Notiflix.Loading.pulse();
        }
        const response = await getUsers({
          page: pageParam,
          pageSize: searchParams ? 0 : 15,
          search: searchParams,
        });
        Notiflix.Loading.remove();
        setMeta(response.data.meta);
        return response.data.data;
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage, pages, lastPageParam) => {
        if (lastPage.length == 0) {
          return lastPageParam;
        } else {
          return lastPageParam + 1;
        }
      },
    });

  const mutation = useMutation({
    mutationFn: registerUser,
    onSuccess: async (data) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Thêm thành công!!!");
      }, 500);
      setIsModalOpen(false);
      dispatch(setLogoSubmit(null));
      refetch();
    },
    onError: (error) => {
      setIsModalOpen(false);
      dispatch(setLogoSubmit(null));
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error?.response?.data?.message);
      }, 1000);
    },
  });

  const edit = useMutation({
    mutationFn: editUser,
    onSuccess: async (data) => {
      dispatch(setModalEditOpen(false));
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Chỉnh sửa thành công!!!");
      }, 500);
      handleCancel();
      dispatch(resetLogoUserChange());
      refetch();
    },
    onError: (error) => {
      dispatch(setModalEditOpen(false));
      dispatch(setLogoSubmit(null));
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(
          error.response.status === 403 &&
            "Bạn không có quyền chỉnh sửa tài khoản"
        );
      }, 1000);
    },
  });

  const delUser = useMutation({
    mutationFn: deleteUser,
    onSuccess: async (data) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Xóa thành công!!!");
      }, 500);
      refetch();
    },
    onError: (error) => {
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(
          error.response.status === 403 &&
            "Bạn không có quyền chỉnh sửa tài khoản"
        );
      }, 1000);
    },
  });

  const handleScroll = useCallback(() => {
    handlers.handleScroll(
      tableRef,
      hasNextPage,
      isFetchingNextPage,
      lastScrollTop,
      setIsLoadingMore,
      fetchNextPage,
      setLastScrollTop
    );
  }, [lastScrollTop]);

  const handleRowClick = (user, notification) => {
    dispatch(setUserSelected(user));
    dispatch(setModalEditOpen(true));
    handlers.handleRowClick(user, dispatch, setLogoUser);
    notification &&
      updateStatusNotification(notification._id, NotiStatus.AGREE).then(() => {
        setIsModalOpenNotification(false);
        return getAllNotification().then((res) => setNotifications(res.data));
      });
  };

  const handleAddUser = async (data) => {
    handlers.handleAddUser(data, logo, mutation, methods);
    handleCancel();
  };
  const handleEditUser = async (data) => {
    handlers.handleEditUser(
      data,
      logo,
      logoUsers,
      userSelected,
      edit,
      dispatch,
      methods
    );
    handleCancel();
    refetch();
  };

  const handleCancel = async () => {
    dispatch(setUserSelected(null));
    dispatch(setModalEditOpen(false));
    handlers.handleCancel(dispatch, methods, setIsModalOpen);
  };
  const handleDeleteUser = () => {
    dispatch(setModalEditOpen(false));
    handlers.handleDeleteUser(userSelected?._id, delUser);
  };

  const showModalAddUser = () => setIsModalOpen(true);
  const handleSearch = (search) => {
    searchParams = search.search;
    refetch();
  };

  const handleExportUserExcel = async () => {
    try {
      const response = await getUsersForExcel();
      const ws = utils.json_to_sheet(response.data);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "UsersData.xlsx");
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  useEffect(() => {
    const listLogoUser = data?.pages.flatMap((pageData) => {
      return pageData.map((user) => {
        return { id: user._id, data: user };
      });
    });
    if (userSelected) {
      dispatch(
        setUserSelected(
          listLogoUser.find((user) => user.id === userSelected?._id)?.data
        )
      );
    }
  }, [data]);

  const [isModalOpenNotification, setIsModalOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState();

  const showModalNotification = () => {
    setIsModalOpenNotification(true);
  };

  const handleCancelNotification = () => {
    setIsModalOpenNotification(false);
  };

  useEffect(() => {
    getAdmin().then((res) => setUser(res.data));
    getAllNotification().then((res) => {
      setNotifications(res.data);
    });
    socketOn("notification", () => {
      getAllNotification().then((res) => setNotifications(res.data));
    });
  }, []);

  const handleAccept = (notification) => {
    updateStatusNotification(notification._id, NotiStatus.AGREE)
      .then((res) => {
        Notiflix.Notify.success("Duyệt mật khẩu thành công!");
        setIsModalOpenNotification(false);
        getAllNotification().then((res) => setNotifications(res.data));
      })
      .catch(() => Notiflix.Notify.failure("Duyệt mật khẩu thất bại!"));
  };

  const handleDeny = (notification) => {
    updateStatusNotification(notification._id, NotiStatus.DENY)
      .then((res) => {
        Notiflix.Notify.success("Duyệt mật khẩu thành công!");
        setIsModalOpenNotification(false);
        getAllNotification().then((res) => setNotifications(res.data));
      })
      .catch(() => Notiflix.Notify.failure("Duyệt mật khẩu thất bại!"));
  };

  const filterUniqueItems = (items) => {
    const uniqueItems = new Map();
    items.forEach((item) => {
      if (!uniqueItems.has(item._id)) {
        uniqueItems.set(item._id, item);
      }
    });
    return Array.from(uniqueItems.values());
  };

  // Process data to remove duplicates before rendering
  const processedData = data?.pages
    ? filterUniqueItems(data.pages.flatMap((pageData) => pageData))
    : [];

  return (
    <div className="body-content">
      <div className="header">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div className="title">
            <span>DANH SÁCH TÀI KHOẢN</span>
          </div>
          <Badge
            size="default"
            count={notifications?.length}
            style={{
              cursor: "pointer",
              position: "absolute",
            }}
            onClick={showModalNotification}
          >
            <Avatar
              shape="circle"
              style={{
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "1px solid #3B54A5",
              }}
              onClick={showModalNotification}
              icon={
                <IoNotificationsSharp
                  style={{ color: "#3B54A5", fontSize: 24 }}
                />
              }
            />
          </Badge>
        </div>

        <Flex vertical>
          <form
            className="search-container"
            onSubmit={handleSubmit(handleSearch)}
          >
            <div className="input-search-container">
              <Input
                className="input-cons"
                register={register}
                placeholder="Tìm kiếm"
                name="search"
                pattern={{
                  value: /^([\p{L}\s]+|[0-9]+)$/u,
                  message: "Nhập tên hoặc số điện thoại",
                }}
              />
            </div>
            <div className="btn-search-container">
              <ButtonAdd className="btn-add-cons">Tìm kiếm</ButtonAdd>
            </div>
            <div className="btn-search-container" style={{ marginLeft: 12 }}>
              <ButtonAdd onClick={handleExportUserExcel}>Xuất excel</ButtonAdd>
            </div>
          </form>
          <div>
            {errors.search && (
              <span className="error-message">{errors.search.message}</span>
            )}
          </div>
        </Flex>
      </div>

      <div className="table-container">
        <table className="table-header">
          <tbody>
            <tr>
              <td>STT</td>
              <td>Tên</td>
              <td style={{ borderLeft: "1px solid white" }}>Mã khách hàng</td>
              <td style={{ borderLeft: "1px solid white" }}>Số điện thoại</td>
              <td>Hãng sơn</td>
            </tr>
          </tbody>
        </table>
        <div className="table-body-user" onScroll={handleScroll} ref={tableRef}>
          <table className="table-content">
            <tbody>
              {processedData.map((user, index) => (
                <tr key={user?._id} onClick={() => handleRowClick(user)}>
                  <td>{meta?.itemCount - index}</td>
                  <td style={{ wordBreak: "break-word" }}>{user?.fullName}</td>
                  <td>{user?.code}</td>
                  <td>{user?.phone}</td>
                  <td>{user?.agentName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isLoadingMore && (
          <div className="loading-more">
            <Space size="large">
              <img
                src={spinner}
                alt="spinner"
                className="spinner"
                height={80}
                width={80}
              />
              <div>
                <span>Đang tải....</span>
              </div>
            </Space>
          </div>
        )}
      </div>
      <div className="btn-container-add-user">
        <div className="btn-add-user">
          <ButtonAdd onClick={showModalAddUser}>Thêm tài khoản</ButtonAdd>
        </div>
      </div>
      <FormProvider {...methods}>
        {isModalOpen && !userSelected && (
          <ModalForm
            isModalOpen={isModalOpen}
            handleOk={handleAddUser}
            handleCancel={handleCancel}
            listPaints={listPaints}
          />
        )}
        {/* Modal chỉnh user */}
        <ModalEditForm
          key={userSelected?._id}
          handleDelete={handleDeleteUser}
          isModalOpen={isModalEditOpen}
          handleOk={handleEditUser}
          handleCancel={handleCancel}
          listPaints={listPaints}
          refetch={refetch}
        />
      </FormProvider>

      <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        title={
          <h4
            style={{
              fontWeight: "600",
              fontSize: "26px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Thông báo
          </h4>
        }
        open={isModalOpenNotification}
        onCancel={handleCancelNotification}
      >
        {notifications?.length > 0 ? (
          notifications?.map((notification, index) => (
            <div
              key={index}
              style={{
                padding: "10px 0",
                borderBottomWidth: "2px",
                borderBottomStyle: "inset",
              }}
            >
              {notification.type === "PASSWORD" ? (
                <>
                  <p style={{ marginBottom: 0 }}>
                    Tài khoản {notification?.user?.fullName} yêu cầu thay đổi
                    mật khẩu
                  </p>
                  {user?.role === "ADMIN" && (
                    <div
                      style={{
                        display: "flex",
                        gap: 30,
                        width: "60%",
                        margin: "10px 0px",
                      }}
                    >
                      <ButtonLogout onClick={() => handleDeny(notification)}>
                        Từ chối
                      </ButtonLogout>
                      <ButtonAdd
                        onClick={() => handleAccept(notification)}
                        style={{ borderRadius: "5px" }}
                      >
                        Chấp nhận
                      </ButtonAdd>
                      <div style={{ flex: 2 }}></div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p
                    style={{ marginBottom: 0, cursor: "pointer" }}
                    onClick={() =>
                      handleRowClick(notification?.user, notification)
                    }
                  >
                    Vừa có thêm tài khoản tên {notification?.user?.fullName}
                  </p>
                </>
              )}
            </div>
          ))
        ) : (
          <p>Không có thông báo mới!</p>
        )}
      </Modal>
    </div>
  );
}

export default UserMan;
