import React, { useEffect } from "react";
import "./Home.scss";
import { Navbar } from "../../components/index";
import { Outlet, useLocation } from "react-router-dom";
import { socketOn } from "../../services/socketServices";

function Home() {
  const location = useLocation();

  const isSolutionPage = location.pathname === "/solution";

  useEffect(() => {
    socketOn("connect", () => {
      console.log("Soket Connected");
    });

    // Disconnect event
    socketOn("disconnect", () => {
      console.log("Soket Disconnected");
    });
  }, []);

  return (
    <div className="contain">
      <div className="home">
        <Navbar />
        <div className={`content ${isSolutionPage ? "solution" : ""}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Home;
