import React, { useRef, Fragment } from "react";
import "./Button.scss";

export const Button = ({ children, ...props }) => {
  return (
    <button type="submit" className="btn" {...props}>
      {children}
    </button>
  );
};

export const ButtonAdd = ({ children, ...props }) => {
  return (
    <button type="submit" className="btn-add" {...props}>
      {children}
    </button>
  );
};

export const ButtonFile = ({ children, ...props }) => {
  const { onChange } = props;
  const inputFileRef = useRef(null);
  const onClick = () => {
    inputFileRef.current.click();
  };
  return (
    <div className="btn-file" onClick={onClick}>
      <span>Nhập file excel</span>
      <input
        hidden
        type="file"
        ref={inputFileRef}
        {...props}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};

export const ButtonLogout = ({ children, ...props }) => {
  return (
    <button className="btn-logout" {...props}>
      {children}
    </button>
  );
};

function ButtonBorder({
  type = "button",
  content,
  color = "#525252",
  bgcolor = "#fff",
  onClick,
}) {
  return (
    <button
      className="buttonBorder"
      type={type}
      style={{ color: `${color}`, backgroundColor: `${bgcolor}` }}
      onClick={onClick}
    >
      {content}
    </button>
  );
}

export { ButtonBorder };
