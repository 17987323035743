import AxiosClient from "./axiosClient";

const API_ENDPOINT = "construction/";

export const getCons = (data) => {
  return AxiosClient.get(API_ENDPOINT, { params: data });
};

export const createConstruct = (data) => {
  return AxiosClient.post(API_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editConstruct = (data) => {
  return AxiosClient.put(API_ENDPOINT + `${data.idCons}`, {
    user: data.user,
    consDefault: data.consDefault,
  });
};

export const editDetailConstruct = (data) => {
  return AxiosClient.put(API_ENDPOINT + 'detail/' + `${data.idCons}`, data?.data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteConstruct = (id) => {
  return AxiosClient.delete(API_ENDPOINT + `${id}`);
};
