import { configureStore } from "@reduxjs/toolkit";
import user from "./user/userSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import cons from "./cons/consSlice";
import color3dReducer from "./solution/color3dSclice";
import logoUserReducer from "./logoUser/logoUserSlice";
import reloadReducer from "./reload/reloadSlice";
import modal from "./modal/modalSlice";

export const store = configureStore({
   reducer: {
      color3dSlice: color3dReducer,
      user,
      cons,
      logoUser: logoUserReducer,
      reload: reloadReducer,
      modal
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
});

setupListeners(store.dispatch);
