import AxiosClient from "./axiosClient";

const API_ENDPOINT = "/paint";

export const getListPaints = (id, data) => {
  return AxiosClient.get(API_ENDPOINT + `/${id}/brand-paint/`, {
    params: data,
  });
};

export const getListPaintsTest = (brandPaintId) => {
  return AxiosClient.get(API_ENDPOINT + `/${brandPaintId}/brand-paint`);
};
